import { graphql, useStaticQuery } from "gatsby"

export const useProjectsCardsThumbnails = () => {
  const { thumbnails } = useStaticQuery(
    graphql`
      query ProjectsCardsThumbnails {
        thumbnails: allFile(
          filter: { relativeDirectory: { eq: "projects-cards" } }
          sort: { fields: relativePath }
        ) {
          edges {
            node {
              relativePath
              childImageSharp {
                fixed(width: 600) {
                  src
                  srcSet
                  srcSetWebp
                  width
                  height
                }
              }
            }
          }
        }
      }
    `
  )
  return thumbnails
}

export const getThumbnailByLink = (link, thumbnails) => {
  const filteredThumbnails =
    thumbnails &&
    thumbnails.edges &&
    thumbnails.edges
      .filter(
        ({ node }) =>
          node && node.relativePath && node.relativePath.includes(link)
      )
      .map(({ node: { childImageSharp } }) => ({
        childImageSharp,
      }))
  return (
    (filteredThumbnails &&
      filteredThumbnails.length > 0 &&
      filteredThumbnails[0]) ||
    null
  )
}
