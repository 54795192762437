import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import componentStyles from "./consulting.module.scss"
import ConsultingIllustration from "../../assets/images/consulting-illustration.svg"
import Button from "../../components/Button/Button"
import { projectsList, servicesList } from "../../hooks"
import { useProjectsCardsThumbnails } from "../../hooks/useProjectsCardsThumbnails"
import services from "../services.module.scss"
import PresentationForm from "../../components/PresentationForm/PresentationForm"

const workListItems = [
  {
    titlePath: "services.consulting.work-block.list.first-item.title",
    textPath: "services.consulting.work-block.list.first-item.text",
  },
  {
    titlePath: "services.consulting.work-block.list.second-item.title",
    textPath: "services.consulting.work-block.list.second-item.text",
  },
  {
    titlePath: "services.consulting.work-block.list.third-item.title",
    textPath: "services.consulting.work-block.list.third-item.text",
  },
  {
    titlePath: "services.consulting.work-block.list.forth-item.title",
    textPath: "services.consulting.work-block.list.forth-item.text",
  },
]

const Consulting: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()
  const projects = projectsList
  const projectsThumbnails = useProjectsCardsThumbnails()
  const serviceList = servicesList
  const bannerClasses = classNames(componentStyles.banner)
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
    "grid-2"
  )
  const consultingClasses = classNames(componentStyles.consulting, "container")
  const consultingFirstBlock = classNames(
    componentStyles.consultingFirstBlock,
    "grid-2"
  )
  const consultingFirstBlockTitleClasses = classNames(
    componentStyles.consultingFirstBlockTitle
  )

  const projectsClasses = classNames(
    componentStyles.consultingProjects,
    "container"
  )
  const projectsListClasses = classNames(
    componentStyles.consultingProjectsList,
    "grid-2"
  )
  const projectsTitleClasses = classNames(
    componentStyles.consultingProjectsTitle,
    "large-section-title"
  )
  const blockInServices = classNames(services.blockInServices, "container")
  const blockInServicesList = classNames(services.blockInServicesList, "grid-3")

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "services.consulting.seo.description",
        })}
        title={intl.formatMessage({ id: "services.consulting.seo.title" })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <h1 className={componentStyles.bannerTitle}>
            {intl.formatMessage({
              id: "services.consulting.title",
            })}
          </h1>
          <div className={componentStyles.bannerImage}>
            <img
              className={componentStyles.bannerImageMainImage}
              src={ConsultingIllustration}
              alt="Illustration with man standing in front of dashboards"
              width="500"
              height="400"
            />
          </div>
          <div>
            <div className={componentStyles.bannerText}>
              {intl.formatMessage({
                id: "services.consulting.banner-text",
              })}
            </div>
            <PresentationForm/>
          </div>
        </div>
      </section>
      <section className={consultingClasses}>
        <h4 className={consultingFirstBlockTitleClasses}>
          {intl.formatMessage({
            id: "services.consulting.work-block.title",
          })}
        </h4>
        <div className={consultingFirstBlock}>
          {workListItems.map(({ textPath, titlePath }) => (
            <div
              key={titlePath}
              className={componentStyles.consultingFirstBlockItem}
            >
              <h5>
                {intl.formatMessage({
                  id: titlePath,
                })}
              </h5>
              <p>
                {intl.formatMessage({
                  id: textPath,
                })}
              </p>
            </div>
          ))}
        </div>
      </section>
      <section className={blockInServices}>
        <h2 className={projectsTitleClasses}>
          {intl.formatMessage({ id: "index.services.top-title" })}
        </h2>
        <ul className={blockInServicesList}>
          {serviceList.map(({ title, link }) => {
            const path = "/services/" + link
            if (
              "/" + intl.locale + path !== location.pathname &&
              "/" + intl.locale + path + "/" !== location.pathname
            ) {
              return (
                <li key={title} className={services.blockInServicesListItem}>
                  <h3 className={services.blockInServicesListItemTitle}>
                    {intl.formatMessage({ id: title })}
                  </h3>
                  <Button
                    to={path}
                    className={services.blockInServicesListButton}
                  >
                    {intl.formatMessage({ id: "services.button-title" })}
                  </Button>
                </li>
              )
            }
          })}
        </ul>
      </section>
    </Layout>
  )
}
export default Consulting
